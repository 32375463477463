<template>
    <div class="ExploreCon" style="min-height:900px;">
		<div class="card-carousel--card" v-for="(item,index) in rows" :style="{'width':width + 'px','height':width + 135 + 'px'}" @click="DetailBtn(item.pnId,item.pnType)">
			<div class="card-carousel-box">
				<el-image  :style="{'width':width + 'px','height':width + 'px'}" :src="item.pnImage" :fit="fit" style="object-fit: cover;"/>
			</div>
			<div class="card-carousel--card--footer">
				<div class="name">
					{{item.pnName}}
				</div>
				<div style="font-size:14px;">
					<span style="font-weight:bold;">
						<img style="width:18px;height:17px;vertical-align: bottom;" src="../../../../assets/img/id.svg">
					</span>
					<span style="font-weight:bold;color:rgb(112, 122, 131) !important">{{item.pnTokenId}}</span>
				</div>
				<div class="Id" style="color:rgb(112, 122, 131);font-size: 14px;text-align: right;" v-if="item.ssId != 'nft_production_1'">
					非卖品
				</div>
				<div class="Id" style="color:red;font-size: 16px;text-align: right;" v-else>
					<span v-if="item.pnPrice != '0'">¥{{item.pnPrice}}</span>
				</div>
			</div>
			<div class="IconImg">
				<span>
					<font v-if="item.pnType == '2'">#{{item.pnNumb}}</font>
				</span>
				<span>
					<font @click.stop="collectBtn(item.urId,item.pnId,item.pnFavorited,index)">
						<img :src="Image" v-if="item.urId == ''">
						<img :src="loveCur" v-else>
						{{item.pnFavorited}}
					</font>
				</span>
			</div>
			<div class="IconBox">
				<span
					class="Icon" 
					v-if="item.pnMatter == '1'"
				>
					<img class="shiwu" src="../../../../assets/img/shiwu.svg">
				</span>
			</div>
		</div>
    </div>
	<el-dialog
	    width="500px"
		title="出售"
	    v-model="dialogVisible"
	    :before-close="handleClose"
	    :close-on-press-escape="false"
	    :close-on-click-modal="false"
	    :show-close="false"
		:destroy-on-close="true"
		custom-class="dialogSell"
	>	
		<div style="width:450px;height:300px;">
			<div class="title">价格</div>
			<el-input type="number" :min="1" v-model="pnPrice" placeholder="请输入价格" @input="changeVal">
			    <template #prepend>￥</template>
			</el-input>
			<div class="title">手续费({{poundage}}%)</div>
			<el-input v-model="poundageVal" disabled placeholder="手续费">
			    <template #prepend>￥</template>
			</el-input>
			<div class="title">
				<span>
					<el-checkbox v-model="checked" label="限时销售 结束时间" size="large" @change="changeCheck"/>
				</span>
				<span></span>
				<span style="margin-left:5px;">
					<el-date-picker
						v-model="pnSale"
						type="datetime"
						placeholder="选择结束时间"
						value-format="YYYY-MM-DD HH:mm:ss" format="YYYY-MM-DD HH:mm:ss"
					/>
				</span>
			</div>
			<div style="text-align:right;margin-top:40px;">
				<span style="font-size:20px;font-weight:bold;">实际入账: </span>
				<span style="color:red;font-size:20px;font-weight: bold;">￥</span>
				<font style="color:red;font-size:28px;font-weight: bold;">{{amount}}</font>
			</div>
		</div>
	    <template #footer>
	      <span class="dialog-footer">
	        <el-button @click="cancelBtn">取消</el-button>
	        <el-button type="primary" :loading="loading" @click="confirmBtn">确认出售</el-button>
	      </span>
	    </template>
	</el-dialog>
</template>
<script>
	import Icon from "@/components/Icon"
	import Edit from "../Edit/Edit"
	import { ElLoading } from 'element-plus'
	import Image from "../../../../assets/img/Love.png"
	import loveCur from "../../../../assets/img/loveCur.png"
	export default {
		props:['width'],
		inject: ["reload"],
		components:{ ElLoading,Edit,Icon },
		data() {
			return{
				title:"新增作品信息",
				drawerHeight:'',
				loading:false,
				drawer:false,
				checked:false,
				dialogVisible:false,
				imageUrl:'',
				pnPrice:'',
				poundage:'',
				pnSale:'',
				pnId:'',
				rows:[],
				Image: Image,
				loveCur:loveCur,
				currentPage: 1,
				total: 0,
				pageSize:24,
			}
		},
		created(){
			let that = this;
			that.initData();
			that.$nextTick(()=>{
				window.onscroll = function(){
					var scrollTop = document.documentElement.scrollTop||document.body.scrollTop;
					var windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
					var scrollHeight = document.documentElement.scrollHeight||document.body.scrollHeight;
					if(scrollTop+windowHeight==scrollHeight){
						if(that.rows.length < that.total) {
							that.currentPage++;
							that.initData();
						}
					} 
				}
			})
		},
		computed:{
			amount:function () {
				return (Number(this.pnPrice) - (Number(this.poundage)/100 * Number(this.pnPrice))).toFixed(2);
			},
			poundageVal:function () {
				return (Number(this.pnPrice) * (Number(this.poundage)/100)).toFixed(2)
			}
		},
		methods:{
			DetailBtn(pnId,pnType){
				let that = this;
				let urId = window.localStorage.getItem("urId");
				if(urId == undefined || null){
					urId = "";
				}
				if(pnType == '0' || pnType == "1"){
					that.$router.push({
						path:'/Content/Content/'+ pnId +'/'+pnType,
					})
				}else{
					that.$router.push({
						path:'/Content/SubContent/'+ pnId,
					})
				}
			},
			collectBtn(urId,pnId,pnFavorited,index){
				let that = this;
				let url = "nft/pfe/save"
				let urIdStorage = window.localStorage.getItem("urId");
				if(urIdStorage == undefined || urIdStorage == null){
					this.$confirm('您还未登录，是否现在去登录', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					})
					.then(() => {
						that.$router.push(({
							name:"Login"
						}))
					})
					.catch(() => {});
					return false;
				}
				if(urId != ""){
					url = "nft/pfe/delete"
				}
				this.$axios({
					method:'post',
					headers: {
						token: window.localStorage.getItem("token")
					},
					url: url,
					data:this.$qs.stringify({
						urId: urIdStorage,
						pnId: pnId,
						urName: window.localStorage.getItem("urName"),
						urHead: window.localStorage.getItem("urHead"),
					})
				})
				.then((res)=>{
					let msg = res.data.msg;
					if(msg == 2){
						if(urId == ""){
							that.rows[index].urId = "12347";
							that.rows[index].pnFavorited = Number(that.rows[index].pnFavorited) + 1;
						}else{
							that.rows[index].urId = "";
							that.rows[index].pnFavorited = Number(that.rows[index].pnFavorited) - 1;
						}
						that.$message.success("操作成功！");
						that.$emit("getNumber")
					}else{
						that.$message.error(msg);
					}
				})
				.catch((err)=>console.log('请求失败',err))
			},
			confirmBtn(){
				let that = this;
				let checked = that.checked;
				let pnPrice = that.pnPrice;
				let pnSale = that.pnSale;
				if(pnPrice == ""){
					that.$message.error("请输入价格!");
					return false;
				}
				if(checked){
					if(pnSale == ""){
						that.$message.error("请选择结束时间!");
						return false;
					}
				}
				that.loading = true;
				that.$axios({
					method:'post',
					url: 'nft/pn/authSale',
					headers: {
						token:window.localStorage.getItem("token")
					},
					data:this.$qs.stringify({
						pnId: that.pnId,
						pnPrice: that.pnPrice,
						pnSale: pnSale
					})
				})
				.then((res)=>{
					let msg = res.data.msg;
					if(msg == 2){
						that.$message.success("出售成功!");
						that.dialogVisible = false;
						that.pnSale = "";
						that.pnPrice = "";
						that.checked = false;
						that.$emit("getNumber");
					}else{
						that.$message.error(msg);
					}
					that.loading = false;
				})
				.catch((err)=>console.log('请求失败',err))
			},
			cancelBtn(){
				let that = this;
				that.dialogVisible = false;
				that.pnSale = "";
				that.pnPrice = "";
				that.checked = false;
			},
			sellBtn(pnId){
				let that = this;
				that.pnId = pnId;
				that.$axios({
					method:'post',
					url: 'sys/py/getService',
					headers: {
						token:window.localStorage.getItem("token")
					},
					data:this.$qs.stringify({
						pnId: pnId
					})
				})
				.then((res)=>{
					that.poundage = res.data.msg;
					that.dialogVisible = true;
				})
				.catch((err)=>console.log('请求失败',err))
			},
			changeCheck(val){
				if(!val){
					this.pnSale ="";
				}
			},
			changeVal(val){
				if(Number(val) <= 0){
					this.pnPrice = 1;
				}
			},
			editBtn(pnId,pnEdit){
				let that = this;
				if(pnEdit == "0"){
					that.$message.error("当前不可编辑!");
					return false;
				}
				that.$router.push({
					path:'/Create/'+ pnId,
				})
				// that.reload()
			},
			delBtn(pnId,pnEdit){
				let that = this;
				if(pnEdit == "0"){
					that.$message.error("当前状态不可删除!");
					return false;
				}
				this.$confirm('确定要删除吗?删除将无法恢复', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				})
				.then(() => {
					that.$axios({
						method:'post',
						url: 'nft/pn/delete',
						headers: {
							token:window.localStorage.getItem("token")
						},
						data:this.$qs.stringify({
							pnId: pnId
						})
					})
					.then((res)=>{
						let msg = res.data.msg;
						if(msg==2){
							that.$message.success("删除成功！");
							that.initData();
							that.$emit("getNumber");
						}else{
							that.$message.error(msg);
						}
					})
					.catch((err)=>console.log('请求失败',err))
				})
				.catch(() => {});
			},
			initData(){
				let that = this;
				let token = window.localStorage.getItem("token");
				let urId = window.localStorage.getItem("urId");
				if(urId == undefined || null){
					urId = "";
				}
				if(token == undefined || token == null){
					token = "";
				}
				let loading = ElLoading.service({
					lock: true,
					text: '正在加载',
					background: 'rgba(0, 0, 0, 0.7)',
				})
				this.$axios({
					method:'post',
					url:'nft/pn/getMine',
					headers: {
						token: token
					},
					data:this.$qs.stringify({
						pnAuthor: that.$route.params.urId,
						urId: urId,
						page: that.currentPage,
						limit: that.pageSize
					})
				})
				.then((res)=>{
					let rows = res.data.dataRoot;
					rows.forEach((item)=>{
						let pnPrice = item.pnPrice;
						if(pnPrice == "" || pnPrice == "0.00"){
							item.pnPrice = "0";
						}
					})
					that.rows = that.rows.concat(rows);
					that.total = res.data.totalCounts;
					loading.close();
				})
				.catch((err)=>console.log('请求失败',err))
			},
		},
	};
</script>
<style scoped>
	.card-carousel--card--footer .Id{
		font-weight: bold;
	}
	.ExploreCon{
		padding-top:20px;
	}
	.card-carousel--card--footer{
		padding:12px !important;
	}
	.card-carousel--card--footer .name{
		font-size:16px;
		font-weight:bold;
		margin-bottom:5px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
	.card-carousel--card--footer .Id{
		height:16px;
		font-size:12px;
		margin-top:5px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		color:#999;
	}
	.shiwu{
		width:22px !important;
		height:22px !important;
		vertical-align:sub !important;
	}
	.IconImg{
		display: flex;
		justify-content: space-between;
		color:rgb(112, 122, 131) !important;
		padding:12px;
		background: linear-gradient(rgba(229, 232, 235, 0.392) 0%,rgb(255, 255, 255) 20%);
		border-bottom-left-radius: 10px;
		border-bottom-right-radius: 10px;
	}
	.IconImg span{
		width:50%;
	}
	.IconImg span:last-child{
		text-align: right;
	}
	.IconImg img{
		width: 20px !important;
		height: 20px !important;
		vertical-align: bottom;
		margin-right:5px;
	}
	.dialogSell .el-dialog__body{
		height:250px !important;
	}
	.dialogSell .title{
		margin:15px 0px;
		font-weight: bold;
		font-size:16px;
	}
</style>
