<template>
    <div class="ExploreCon" style="min-height:900px;">
		<div class="card-carousel--card" v-for="(item,index) in rows" :style="{'width':width + 'px','height':width + 135 + 'px'}" @click="DetailBtn(item.pnId,item.pnType)">
			<div class="card-carousel-box">
				<el-image :style="{'width':width + 'px','height':width + 'px'}" :src="item.pnImage" :fit="fit" style="object-fit: cover;"/>
			</div>
			<div class="card-carousel--card--footer">
				<div class="name">
					{{item.pnName}}
				</div>
				<div style="font-size:14px;">
					<span style="font-weight:bold;">
						<img style="width:18px;height:17px;vertical-align: bottom;" src="../../../../assets/img/id.svg">
					</span>
					<span style="font-weight:bold;color:rgb(112, 122, 131) !important">{{item.pnTokenId}}</span>
				</div>
				<div class="Id" style="color:rgb(112, 122, 131);font-size: 14px;text-align: right;" v-if="item.ssId != 'nft_production_1'">
					非卖品
				</div>
				<div class="Id" style="color:red;font-size: 16px;text-align: right;" v-else>
					<span v-if="item.pnPrice != '0'">¥{{item.pnPrice}}</span>
				</div>
			</div>
			<div class="IconImg">
				<span>
					<font  v-if="item.pnType == '2'">#{{item.pnNumb}}</font>
				</span>
				<span>
					<font @click.stop="collectBtn(item.urId,item.pnId,item.pnFavorited,index)">
						<img :src="Image" v-if="item.urId == ''">
						<img :src="loveCur" v-else>
						{{item.pnFavorited}}
					</font>
				</span>
			</div>
			<div class="IconBox">
				<span
					class="Icon" 
					v-if="item.pnMatter == '1'"
				>
					<img class="shiwu" src="../../../../assets/img/shiwu.svg">
				</span>
			</div>
		</div>
    </div>
</template>
<script>
	import Icon from "@/components/Icon"
	import Edit from "../Edit/Edit"
	import { ElLoading } from 'element-plus'
	import Image from "../../../../assets/img/Love.png"
	import loveCur from "../../../../assets/img/loveCur.png"
	export default {
		props:['width'],
		inject: ["reload"],
		components:{ ElLoading,Edit,Icon },
		data() {
			return{
				title:"新增作品信息",
				drawerHeight:'',
				loading:false,
				drawer:false,
				imageUrl:'',
				rows:[],
				Image: Image,
				loveCur:loveCur,
				currentPage: 1,
				total: 0,
				pageSize:24,
				obj:{}
			}
		},
		created(){
			let that = this;
			that.initData();
			that.$nextTick(()=>{
				window.onscroll = function(){
					var scrollTop = document.documentElement.scrollTop||document.body.scrollTop;
					var windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
					var scrollHeight = document.documentElement.scrollHeight||document.body.scrollHeight;
					if(scrollTop+windowHeight==scrollHeight){
						if(that.rows.length < that.total) {
							that.currentPage++;
							that.initData();
						}
					} 
				}
			})
		},
		methods:{
			DetailBtn(pnId,pnType){
				let that = this;
				let urId = window.localStorage.getItem("urId");
				if(urId == undefined || null){
					urId = "";
				}
				if(pnType == '0' || pnType == "1"){
					that.$router.push({
						path:'/Content/Content/'+ pnId +'/'+pnType,
					})
				}else{
					that.$router.push({
						path:'/Content/SubContent/'+ pnId,
					})
				}
			},
			collectBtn(urId,pnId,pnFavorited,index){
				let that = this;
				let url = "nft/pfe/save"
				let urIdStorage = window.localStorage.getItem("urId");
				if(urIdStorage == undefined || urIdStorage == null){
					this.$confirm('您还未登录，是否现在去登录', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					})
					.then(() => {
						that.$router.push(({
							name:"Login"
						}))
					})
					.catch(() => {});
					return false;
				}
				if(urId != ""){
					url = "nft/pfe/delete"
				}
				this.$axios({
					method:'post',
					headers: {
						token: window.localStorage.getItem("token")
					},
					url: url,
					data:this.$qs.stringify({
						urId: urIdStorage,
						pnId: pnId,
						urName: window.localStorage.getItem("urName"),
						urHead: window.localStorage.getItem("urHead"),
					})
				})
				.then((res)=>{
					let msg = res.data.msg;
					if(msg == 2){
						if(urId == ""){
							that.rows[index].urId = "12347";
							that.rows[index].pnFavorited = Number(that.rows[index].pnFavorited) + 1;
						}else{
							that.rows[index].urId = "";
							that.rows[index].pnFavorited = Number(that.rows[index].pnFavorited) - 1;
						}
						that.$message.success("操作成功！");
						that.$emit("getNumber")
					}else{
						that.$message.error(msg);
					}
				})
				.catch((err)=>console.log('请求失败',err))
			},
			shelvesBtn(pnId){
				let that = this;
				let token = window.localStorage.getItem("token");
				if(token == undefined || token == null){
					token = "";
				}
				this.$confirm('确定要下架吗?下架将无法恢复', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				})
				.then(() => {
					that.$axios({
						method:'post',
						url: 'nft/pn/soldOut',
						headers: {
							token: token
						},
						data:this.$qs.stringify({
							pnId: pnId
						})
					})
					.then((res)=>{
						let msg = res.data.msg;
						if(msg==2){
							that.rows = [];
							that.$message.success("操作成功！");
							that.$emit("getNumber");
							that.initData();
						}else{
							that.$message.error(msg);
						}
					})
					.catch((err)=>console.log('请求失败',err))
				})
				.catch(() => {});
			},
			initData(){
				let that = this;
				let token = window.localStorage.getItem("token");
				let urId = window.localStorage.getItem("urId");
				if(urId == undefined || null){
					urId = "";
				}
				if(token == undefined || token == null){
					token = "";
				}
				let loading = ElLoading.service({
					lock: true,
					text: '正在加载',
					background: 'rgba(0, 0, 0, 0.7)',
				})
				this.$axios({
					method:'post',
					url:'nft/pn/getSales',
					headers: {
						token: token
					},
					data:this.$qs.stringify({
						pnOwner: that.$route.params.urId,
						urId: urId,
						page: that.currentPage,
						limit: that.pageSize
					})
				})
				.then((res)=>{
					let rows = res.data.dataRoot;
					rows.forEach((item)=>{
						let pnPrice = item.pnPrice;
						if(pnPrice == "" || pnPrice == "0.00"){
							item.pnPrice = "0";
						}
					})
					that.rows = that.rows.concat(rows);
					that.total = res.data.totalCounts;
					loading.close();
				})
				.catch((err)=>console.log('请求失败',err))
			},
		},
	};
</script>
<style scoped>
	.card-carousel--card--footer .Id{
		font-weight: bold;
	}
	.ExploreCon{
		padding-top:20px;
	}
	.card-carousel--card--footer{
		padding:12px !important;
	}
	.card-carousel--card--footer .name{
		font-size:16px;
		font-weight:bold;
		margin-bottom:5px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
	.card-carousel--card--footer .Id{
		height:16px;
		font-size:12px;
		margin-top:5px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		color:#999;
	}
	.shiwu{
		width:22px !important;
		height:22px !important;
		vertical-align:sub !important;
	}
	.IconImg{
		display: flex;
		justify-content: space-between;
		color:rgb(112, 122, 131) !important;
		padding:12px;
		background: linear-gradient(rgba(229, 232, 235, 0.392) 0%,rgb(255, 255, 255) 20%);
		border-bottom-left-radius: 10px;
		border-bottom-right-radius: 10px;
	}
	.IconImg span{
		width:50%;
	}
	.IconImg span:last-child{
		text-align: right;
	}
	.IconImg img{
		width: 20px !important;
		height: 20px !important;
		vertical-align: bottom;
		margin-right:5px;
	}
</style>
